import { computed, defineComponent, onMounted } from "vue";
import { ElConfigProvider } from "element-plus";
import CouponNotice from "./components/CouponNotice.vue";
import { useStore } from "vuex";
import { getInviteCode, setCookie } from "./util";
const langs = {
  zh: import("element-plus/lib/locale/lang/zh-cn"),
  en: import("element-plus/lib/locale/lang/en")
  // bn: import("element-plus/lib/locale/lang/bn"),
  // fa: import("element-plus/lib/locale/lang/fa"),
  // km: import("element-plus/lib/locale/lang/km"),
  // pl: import("element-plus/lib/locale/lang/pl"),
  // tk: import("element-plus/lib/locale/lang/tk"),
  // ca: import("element-plus/lib/locale/lang/ca"),
  // fi: import("element-plus/lib/locale/lang/fi"),
  // ko: import("element-plus/lib/locale/lang/ko"),
  // "pt-br": import("element-plus/lib/locale/lang/pt-br"),
  // tr: import("element-plus/lib/locale/lang/tr"),
  // cs: import("element-plus/lib/locale/lang/cs"),
  // fr: import("element-plus/lib/locale/lang/fr"),
  // ku: import("element-plus/lib/locale/lang/ku"),
};
export default defineComponent({
  components: {
    ElConfigProvider,
    CouponNotice
  },
  setup() {
    const store = useStore();
    const loading = computed(() => store.state.loading);

    // 获取邀请码
    const inviteCode = getInviteCode();
    if (inviteCode) {
      setCookie("INVITE_CODE", inviteCode, 2);
    }

    //store.dispatch("getUserInfo");

    const lang = computed(() => store.state.lang);
    onMounted(() => {
      document.getElementById("gujiaping").remove();
      document.addEventListener("loading", function () {
        store.dispatch("setLoading", true);
      });
      document.addEventListener("loading-close", function () {
        store.dispatch("setLoading", false);
      });
    });
    return {
      loading,
      store,
      locale: langs[lang],
      zIndex: 3000
    };
  }
});