import { computed, defineComponent, onMounted, ref } from "vue";
import CouponItem from "./CouponItem.vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import api from "@/api";
import { setCookie } from "@/util";
import { ElMessage } from "element-plus";
import NewCouponNotice from "./NewCouponNotice.vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  components: {
    CouponItem,
    NewCouponNotice
  },
  setup() {
    const couponList = ref([]);
    const listNoticeVisible = ref(false);
    const newNoticeVisible = ref(false);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isRenew = computed(() => !!route.query.renew);
    const getNoUseCouponList = async () => {
      const res = await api.coupon.getNoUseCouponList();
      couponList.value = res;
      if (res.length) {
        listNoticeVisible.value = true;
      }
    };
    const activeUser = async () => {
      if (listNoticeVisible.value) {
        return;
      }
      if (!store.state.isFirstEveryday) {
        newNoticeVisible.value = true;
        return;
      }
      await api.user.activeUser();
      newNoticeVisible.value = true;
    };
    const onListNoticeClose = async (type = false) => {
      listNoticeVisible.value = false;
      if (type) {
        // todo
      }
      await activeUser();
    };
    const handleTake = async ({
      planId
    }) => {
      await api.coupon.takeCoupon({
        planId
      });
      ElMessage.success("领取成功");
      await getNoUseCouponList();
    };
    onMounted(async () => {
      if (store.state.isLogin && !["guidePay", "payStatus", "login", "pay", "userBindMobile"].includes(route.name)) {
        setCookie("is_first_everyday", false, 1);
        await getNoUseCouponList();
        await activeUser();
      }
    });
    const handleToMyCoupon = () => {
      router.push({
        name: "coupon"
      });
      listNoticeVisible.value = false;
    };
    const handleUse = () => {
      router.push({
        name: "home"
      });
      listNoticeVisible.value = false;
    };
    return {
      dayjs,
      couponList,
      listNoticeVisible,
      newNoticeVisible,
      onListNoticeClose,
      handleToMyCoupon,
      store,
      handleTake,
      handleUse,
      isRenew
    };
  }
});