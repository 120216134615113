import api from "@/api";
import { reactive, toRefs, computed, watch } from "vue";
import { useRouter } from "vue-router";
import CouponItem from "../../components/CouponItem.vue";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isRenew: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    param: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    CouponItem
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      couponType: "enable",
      couponList: [],
      product: {
        num: [1]
      },
      selectCoupon: false,
      preOrder: {},
      loading: false,
      orderInfo: {
        couponData: {},
        skuId: null,
        num: 1,
        couponCode: ""
      }
    });
    const router = useRouter();
    const selectedSku = computed(() => props.data ? props.data.find(item => item.skuId === state.orderInfo.skuId) : []);
    const couponCodeText = computed(() => {
      let txt = "";
      if (!state.orderInfo.couponCode || !state.preOrder.couponInfo) {
        return "";
      }
      if (state.preOrder.couponInfo.type === 1 || state.preOrder.couponInfo.type === 3) {
        txt = `满￥${state.preOrder.couponInfo.withAmount}`;
      }
      if (state.preOrder.couponInfo.type === 5) {
        txt += "兑换1件";
      } else if (state.preOrder.couponInfo.type === 1 || state.preOrder.couponInfo.type === 2) {
        txt += `减￥${state.preOrder.couponInfo.amount}`;
      } else {
        txt += `${state.preOrder.couponInfo.discount / 10}折`;
      }
      return `${state.preOrder.couponInfo.title}(${txt})已减${state.preOrder.couponDiscountAmount}`;
    });
    const handleOpenSelectCoupon = async () => {
      if (state.selectCoupon === true) {
        return;
      }
      await getCouponList();
      state.selectCoupon = true;
    };
    const handleSelectCoupon = async couponData => {
      state.selectCoupon = false;
      state.orderInfo.couponData = couponData;
      state.orderInfo.couponCode = "";
      await onGetPreOrder();
    };
    const handleFocusCoupon = async () => {
      state.orderInfo.couponData = {};
      await onGetPreOrder();
    };
    const handleCouponCodeChange = async () => {
      state.orderInfo.couponData = {};
      await onGetPreOrder();
    };
    const onGetPreOrder = async () => {
      var _state$orderInfo$coup;
      const preOrder = await api.home.createReserveOrder({
        products: [{
          skuId: state.orderInfo.skuId,
          num: state.orderInfo.num
        }],
        couponCode: state.orderInfo.couponCode,
        couponId: (_state$orderInfo$coup = state.orderInfo.couponData.userCoupon) === null || _state$orderInfo$coup === void 0 ? void 0 : _state$orderInfo$coup.couponId,
        type: 1
      });
      state.preOrder = preOrder;
    };
    const getCouponList = async () => {
      const res = await api.coupon.getOrderCoupontList({
        products: [{
          skuId: state.orderInfo.skuId,
          num: state.orderInfo.num
        }]
      });
      state.couponList = res.items;
    };
    watch(() => state.orderInfo.skuId, () => {
      state.orderInfo.couponData = {};
      state.orderInfo.couponCode = "";
      onGetPreOrder();
    });
    watch(() => props.visible, async () => {
      if (props.visible) {
        state.orderInfo.skuId = props.data ? props.data[0].skuId : null;
        const oldOrder = await api.order.getHistoryOrder();
        if (oldOrder) {
          ElMessageBox.confirm("您有尚未支付订单,是否继续交易", "提示", {
            confirmButtonText: "去支付",
            cancelButtonText: "取消订单",
            closeOnClickModal: false,
            closeOnPressEscape: false,
            type: "warning"
          }).then(() => {
            router.push({
              name: "pay",
              query: {
                orderId: oldOrder.orderId
              }
            });
          }).catch(async () => {
            await api.order.cancelHistoryOrder();
            ElMessage({
              type: "info",
              message: "历史订单已取消"
            });
            onGetPreOrder();
          });
        }
      }
    });
    const handleToPay = async () => {
      if (state.loading) {
        return;
      }
      state.loading = true;
      let order = null;
      try {
        if (props.isRenew) {
          var _state$orderInfo$coup2;
          order = await api.home.createRenewOrder({
            products: [{
              skuId: state.orderInfo.skuId,
              num: state.orderInfo.num
            }],
            couponCode: state.orderInfo.couponCode,
            couponId: (_state$orderInfo$coup2 = state.orderInfo.couponData.userCoupon) === null || _state$orderInfo$coup2 === void 0 ? void 0 : _state$orderInfo$coup2.couponId,
            type: 2,
            ...props.param
          });
        } else {
          var _state$orderInfo$coup3;
          order = await api.home.createOrder({
            products: [{
              skuId: state.orderInfo.skuId,
              num: state.orderInfo.num
            }],
            couponCode: state.orderInfo.couponCode,
            couponId: (_state$orderInfo$coup3 = state.orderInfo.couponData.userCoupon) === null || _state$orderInfo$coup3 === void 0 ? void 0 : _state$orderInfo$coup3.couponId,
            type: 1,
            ...props.param
          });
        }
        state.loading = false;
        router.push({
          path: "/pay",
          query: {
            orderId: order.orderId
          }
        });
        //GA自定义事件,不要修改
        gtag("event", "begin_checkout", {
          transaction_id: order.orderId,
          value: 0.1,
          currency: "CNY"
        });
      } catch (error) {
        state.loading = false;
      }
    };
    return {
      ...toRefs(state),
      selectedSku,
      onGetPreOrder,
      handleToPay,
      handleSelectCoupon,
      handleOpenSelectCoupon,
      handleCouponCodeChange,
      handleFocusCoupon,
      store,
      couponCodeText
    };
  }
};