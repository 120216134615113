import { defineComponent, onMounted, ref, watch } from "vue";
import CouponItem from "./CouponItem.vue";
import dayjs from "dayjs";
import api from "@/api";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    CouponItem
  },
  setup() {
    const newCouponList = ref([]);
    let timer = null;
    const newNoticeVisible = ref(false);
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    watch(() => store.state.newCouponNotice, async () => {
      await getNewSendCouponList();
    });
    const getNewSendCouponList = async () => {
      clearTimeout(timer);
      const res = await api.coupon.getNewSendCouponList();
      newCouponList.value = res;
      if (res.length) {
        newNoticeVisible.value = true;
      } else {
        newNoticeVisible.value = false;
      }
      timer = setTimeout(async () => {
        await getNewSendCouponList();
      }, 30000);
    };
    const handleTake = async ({
      planId
    }) => {
      await api.coupon.takeCoupon({
        planId
      });
      ElMessage.success("领取成功");
      newNoticeVisible.value = false;
      router.push({
        name: "coupon"
      });
    };
    onMounted(async () => {
      if (!["guidePay", "payStatus", "login", "pay", "userBindMobile"].includes(route.name)) {
        timer = setTimeout(async () => {
          await getNewSendCouponList();
        }, 60000);
      } else {
        await getNewSendCouponList();
      }
    });
    const onNewNoticeFinish = () => {
      router.push({
        name: "coupon"
      });
      newNoticeVisible.value = false;
    };
    return {
      dayjs,
      handleTake,
      newCouponList,
      newNoticeVisible,
      onNewNoticeFinish
    };
  }
});