import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-50d177a8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sub-container"
};
const _hoisted_2 = {
  class: "sub-main"
};
const _hoisted_3 = {
  class: "left xl-hidden"
};
const _hoisted_4 = {
  class: "user"
};
const _hoisted_5 = {
  class: "info"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "name text-20"
};
const _hoisted_8 = {
  class: "edit"
};
const _hoisted_9 = {
  class: "nav"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = {
  class: "text-20"
};
const _hoisted_12 = {
  class: "right"
};
import { computed } from "vue-demi";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  __name: 'SubLayout',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const userInfo = computed(() => store.state.userInfo);
    const handleClickMenu = async route => {
      if (route.name === "logout") {
        store.dispatch("logout");
      }
      router.push({
        path: route.to
      });
    };
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
        class: "avatar",
        src: _unref(userInfo).avatar
      }, null, 8, _hoisted_6), _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(userInfo).nickname), 1)]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_router_link, {
        class: "edit-link text-20",
        to: "/manage/user"
      }, {
        default: _withCtx(() => [_createTextVNode("编辑资料")]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).getters.menuNav, item => {
        return _openBlock(), _createElementBlock("div", {
          onClick: $event => handleClickMenu(item),
          key: item.name,
          class: _normalizeClass(`nav-item ${_unref(route).name.indexOf(item.name) === 0 ? 'nav-item-active' : ''}`)
        }, [_createElementVNode("a", _hoisted_11, [_createElementVNode("i", {
          class: _normalizeClass(`iconfont icon-${item.icon} text-20`)
        }, null, 2), _createTextVNode(" " + _toDisplayString(item.label), 1)])], 10, _hoisted_10);
      }), 128))])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_router_view)])])]);
    };
  }
};