export const MENU_NAV = [
  {
    name: "order",
    icon: "home",
    label: "我的订单",
    to: "/manage/order",
  },
  {
    name: "notice",
    label: "我的消息",
    icon: "jifenduihuanchanpin",
    to: "/manage/notice",
  },
  {
    name: "coupon",
    label: "我的优惠卷",
    icon: "jifenduihuanchanpin",
    to: "/manage/coupon",
  },
  {
    name: "share",
    label: "我的推广",
    icon: "share",
    to: "/manage/share",
  },

  {
    name: "work",
    label: "我的工单",
    icon: "tag",
    to: "/manage/work/new",
  },
  {
    name: "exchange",
    label: "兑换中心",
    icon: "jifenduihuanchanpin",
    to: "/manage/exchange",
  },
  {
    name: "user",
    label: "个人资料",
    icon: "user",
    to: "/manage/user",
  },

  {
    name: "logout",
    label: "退出登录",
    icon: "exit",
    to: "/login",
  },
];

export const NOLOGIN_MENU_NAV = [
  {
    name: "login",
    label: "去登录",
    icon: "exit",
    to: "/login",
  },
];
